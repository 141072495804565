$transition-time: 250ms;

.ContentPortal {
  width: 95%;
  max-width: 1100px;
  padding: 20px 0 0 0;

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity $transition-time ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity $transition-time ease-out;
  }

  .Content > * {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
