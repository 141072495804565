@use "../../common";

.ExternalLink {
  color: common.$link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
