@use "common";

body {
  margin: 0;
  font-family: common.$font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: common.$bg-color;
}

code {
  font-family: common.$font-family-code;
}
