.Header {
  font-size: 30px;
  margin: auto;
  margin-bottom: 30px;

  &.small {
    font-size: 23px;
  }

  &.subtitle {
    font-size: 20px;
  }
}
